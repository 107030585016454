import React from "react"
import { Redirect } from "@reach/router"

const Objects = props => {
  return (
    <Redirect
      noThrow
      to="/objects/page/1"
    />
  )
}

export default Objects
